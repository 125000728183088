/**
 * Entry point file
 */
 window._led = (function () {
  //ignorei18n_start
  let noOfEditorsLoaded = 0;
  const instances = {};
  const references = {};
  const commonUtils = window["common-utils"];
  const editorTypes = {
    BASIC_EDITOR: 'basicEditor',
    DIFF_EDITOR: 'diffEditor'
  };
  const errorCode = {
    401: "Invalid xsd Input",
    400: "Syntax Error : Invalid XML document",
    404: "Choice Error : Invalid content was found starting with element '{0}'. One of choice Element '{1}' is expected.",
    500: "Invalid value. Expected string",
    501: "Element Error : Cannot find the declaration of element '{0}'",
    502: "Invalid schemaLocation",
    503: "Not mention this schema",
    504: "ElementFormDefault Error:must specify xmlns value",
    505: "'{0}' Error : The value does not meet the '{1}' length requirement.",
    506: "Error: cvc-'{0}'-valid: Value '{1}' is not facet-valid with respect to '{0}' '{2}' ",
    507: "maxInclusive error",
    508: "misMatch pattern",
    509: "enumeration failed",
    510: "Error: '{0}' is not a valid value for '{1}'.",
    511: "not a correct fixed value",
    512: "invalid child ",
    513: "Error: cvc-'{0}': Value '{1}' with respect to '{2}' is not facet-valid with respect to '{0}' '{3}'",
    514: "maxOccur failed in choice",
    515: "Error:  Invalid XML sequence with element '{0}'. One of '{1}' is expected.",
    516: "sequence order is incorrect",
    517: "Required attr is missing",
    518: "The attribute '{0}' is not allowed in this context.",
    519: "Element '{0}' must occur at least '{1}' times.",
    520: "Error: Element '{0}' cannot occur more than '{1}' times",
    521: "Error: Cannot find the declaration of element '{0}'.",
    522: "Error: The markup in the document following the root element must be well-formed.",
    523: "Invalid schemaLocation",
    524: "Must provide schemaLocation",
    525: "Mismatch in end tag : '{0}'",
    526: "Error : Attribute '{0}' is required,but not found",
    527: "Error: This element  is of unexpected type.",
    528: "Error : element '{0}': Sibling elements are mutually exclusive, only one is allowed.",
    529: "cvc-complex-type.2.4.d: Invalid content was found starting with element '{0}'. No child element is expected at this point.",
    530: "The content of element '{0}' is not complete. '{1}' is expected to occur a minimum of '{2}' times. '{2}' more instances are required to satisfy this constraint.",
    531 : "Content of type '{0}' is expected.The following content is not a valid type: '{1}'",
    532 : "Element name '{0}' is invalid.One of the following is expected:- {1}",
    533 : "Expecting no namespace, but the schema document has a target namespace of {0}",
    534 : "Child elements are missing from '{0}' The following elements are expected: '{1}'",
    535 : "'{0}' can occur a maximum of '{1}' times in the current sequence. This limit was exceeded. At this point one of '{2}' is expected.",
    536 : "Invalid content was found starting with element '{0}'. '{1}' is expected to occur a minimum of '{2}' times. '{2}' more instances are required to satisfy this constraint."
  }

   function createErrorString(error,arr){
      if(arr){
        return error.replace(/{(\d+)}/g, function (t, i) {
        return arr[i];
      });}
      else{
        return error;
      }
    }
class CustomError extends Error{
    constructor(error,data,optionals)
    {
      super(createErrorString(errorCode[error],data))
      this.data = data;
      this.errorCode = error;
      this.remain = optionals;
    }
   
  }
  const enumerables = commonUtils.deepFreezer({
    VERSION: '2.4.5',
    EDITOR_SRC_NAME: 'Monaco Editor',
    // LIGHT_THEME: 'vs',
    // DARK_THEME: 'vs-dark',
    // DARK_MODE_CLASS: "darkMode",
    // MONACO_SRC_LOADED: false,
    EDITOR_TYPES: editorTypes,
    SUPPORTED_LANGUAGES: [
      'javascript',
      'html',
      'css'
    ],
    CREATE_ERROR : function(errorCode,data,optionals){
        return new CustomError(errorCode,data,optionals);
    },
    ERROR_CODE : errorCode,
    DESCRIPTOR_CODES: commonUtils.descriptorCodes,
    EDITOR_OPTIONS: {
      [editorTypes.BASIC_EDITOR]: {
        wordWrap: 'on',
        theme: this.LIGHT_THEME,
        formatOnType: true,
        fontSize: '14px'
      },
      [editorTypes.DIFF_EDITOR]: {}
    },
    ACTIONS_MAP: { // ! this should not be modified at any cost in runtime
      copy: "editor.action.clipboardCopyAction",
      cut: "editor.action.clipboardCutAction",
      toggleComment: "editor.action.commentLine",
      addComment: "editor.action.addCommentLine",
      blockComment: "editor.action.blockComment",
      removeComment: "editor.action.removeCommentLine",
      undo: 'undo',
      redo: 'redo',
      format: "editor.action.formatDocument",
      formatSelection: "editor.action.formatSelection",
      delete: "editor.action.deleteLines",
      indent: "editor.action.indentLines",
      outdent: "editor.action.outdentLines",
      openQuickCommand: "editor.action.quickCommand",
      showContextMenu: "editor.action.showContextMenu",
      find: "actions.find",
      duplicate: "editor.action.duplicateSelection"
    },
    LANGS_SUPPORTED_BY_MONACO: {
      /* DONT MODIFY THE CONTENT BELOW, THIS IS AUTOMATICALLY GENERATED */
      /* #I18N_LANG_MAP_FOR_MONACO - START */
      'de': 'de',
      'es': 'es',
      'fr': 'fr',
      'it': 'it',
      'ja': 'ja',
      'ko': 'ko',
      'ru': 'ru',
      'zh-cn': 'zh-cn',
      'zh-tw': 'zh-tw'
      /* #I18N_LANG_MAP_FOR_MONACO - END */
      /* DONT MODIFY THE CONTENT ABOVE, THIS IS AUTOMATICALLY GENERATED */
    }
  });
  const internalObjects = Object.freeze({
    // ? these are the properties which are used by the API functions of lyteEditor
    // ? these can be manipulated but should not be deleted as this is the default state
    _customOptions: {
      [editorTypes.BASIC_EDITOR]: [],
      [editorTypes.DIFF_EDITOR]: []
    },
    _apiToAdd: {},
    _formatters: {},
    _validators: {},
    _formatterFuncs: {},
    _langConfig: {},
    _staticLibs: [],
    _labelledLibs: {},
    _configMap: {
      jsKeys: [
        'compilerOptions',
        'diagnosticsOptions',
        'workerOptions'
      ],
      jsonKeys: [
        'diagnosticsOptions',
        'modeConfiguration'
      ],
      htmlKeys: [
        'options',
        'modeConfiguration'
      ],
      cssKeys: [
        'diagnosticsOptions',
        'modeConfiguration'
      ]
    },
    _loaded: {
      css: false
    },
    _stringToArray: function (stringOrArray) {
      const array = [];
      if (this.checkType.array(stringOrArray)) {
        array.push.apply(array, stringOrArray)
      } else if (this.checkType.string(stringOrArray)) {
        array.push.call(array, stringOrArray)
      } else {
        this.throw.error(_led.error.EXPECTED, 'string (or) array', stringOrArray);
      }
      return array;
    },
    addFeature: function (key, value, options) {
      options = options || {};
      Object.defineProperty(internalApi, key, {
        value: value,
        writable: options.writable || false,
        enumerable: options.enumerable || false,
        configurable: options.configurable || false
      })
    },
    addApi: function (key, value, options) {
      options = options || {};
      Object.defineProperty(internalApi, key, {
        value: value,
        writable: options.writable || false,
        enumerable: options.enumerable || false,
        configurable: options.configurable || true
      });
      if (window.Lyte && window.Lyte.Editor) {
        Object.defineProperty(Lyte.Editor, _led.changeCase.toPascal(key), {
          value: value,
          writable: options.writable || false,
          enumerable: options.enumerable || false,
          configurable: options.configurable || true
        });
      } else {
        _led._apiToAdd[_led.changeCase.toPascal(key)] = {
          value: value,
          writable: options.writable || false,
          enumerable: options.enumerable || false,
          configurable: options.configurable || true
        }
      }
    }
  });

  const internalApi = Object.create(Object.assign({}, enumerables, internalObjects));
  const fnsToAddInInternalApi = {
    getId: function () {
      return 'lyteEditor_' + (('00' + ++noOfEditorsLoaded).slice(-3))//No I18n
    },
    getInstance : function( id ){
      return instances[ id ];
    },
    setInstance: function (id, instance) {
      instances[id] = instance;
    },
    flush: function (id) {
      delete instances[id];
      this.removeReferencesOf(id);
      /**
       * This is to completely remove the instance of the lyteEditor from 
       * global scope
       */
      // if (Object.keys(instances).length === 0) {
      //     // delete _led.monaco;
      // }
    },
    holdReferenceOf: function (node, event, func, id) {
      if (!references.hasOwnProperty(id)) {
        references[id] = [];
      }
      references[id].push([node, event, func]);
    },
    removeReferencesOf: function (id) {
      if (references[id]) {
        references[id].forEach(function (reference) {
          const node = reference[0];
          const event = reference[1];
          const func = reference[2];
          node.removeEventListener(event, func);
        })
      }
    },
    checkEquality: function (actualContent, defaultContent, language, excludeFormat) {
      if (!excludeFormat) {
        if (this._formatters.hasOwnProperty(language)) {
          actualContent = this._formatters[language](actualContent);
          defaultContent = this._formatters[language](defaultContent);
        }
      }
      return actualContent === defaultContent;
    }
  };
  for (let key in fnsToAddInInternalApi) {
    internalApi.addFeature(key, fnsToAddInInternalApi[key]);
  }
  const apiValues = {
    _libs: [],
    _themes: [],
    _listenersAddedToWindow: false,
    I18N_LANG_MAP_FOR_MONACO: internalApi.LANGS_SUPPORTED_BY_MONACO
  }
  //ignorei18n_end
  const exposableApi = {
    urlMappings: {},
    // updateEnum: function (enumerable, value) {
    //   const type = _led.checkType;
    //   const isEnumerable = type.isEnumerable;
    //   const modify = function (key, val) {
    //     Object.defineProperty(internalApi, key, {
    //       value: val,
    //       writable: true
    //     });
    //   }
    //   if (isEnumerable(enumerable)) {
    //     modify(enumerable, value);
    //   } else if (type.object(enumerable)) {
    //     for (let key in enumerable) {
    //       if (isEnumerable(key)) {
    //         modify(key, enumerable[key]);
    //       }
    //     }
    //   }
    // }
  }
  const _led = Object.create(internalApi);
  //ignorei18n_start
  _led.addFeature('deepClone', commonUtils.deepClone);
  _led.addFeature('defineProp', commonUtils.defineProp);
  _led.addFeature('deepFreezer', commonUtils.deepFreezer);
  _led.addFeature('changeCase', commonUtils.changeCase());
  _led.addFeature('pubsub', commonUtils.pubsub());
  _led.addFeature('devLog', commonUtils.devLog);
  _led.addFeature('path', commonUtils.path);
  _led.addFeature('uuid', commonUtils.uuid());
  _led.addFeature('state', commonUtils.state());
  _led.addFeature( 'improperString',function () { 
    return {
      escape: function (value) {
        return value
          .replace(/\./g, '##')
          .replace(/-/g, '&&')
      },
      unescape: function (value) {
        return value
          .replace(/##/g, '.')
          .replace(/&&/g, '-')
      }
    }
  }
  )
  //ignorei18n_end

  _led.constructExposableObject = function () {
    Lyte.Editor = Object.create(Object.assign({}, enumerables, apiValues));
    // _led.defineProp.call(Lyte.Editor, _led.DESCRIPTOR_CODES[0], enumerables);
    for (let key in _led._apiToAdd) {
      Object.defineProperty(Lyte.Editor, key, _led._apiToAdd[key]);
    }
    Lyte.Editor.leftPanel = {
      create : function(id) {
        const object =  {
          dispose : function() {
            _led.flush(id);
          },
          data : {
            
          }
        };
        object.directory = _led.directory();
			  object.folder = _led.folder();
			  object.directory.setEditorInstance(object);
        object.folder.setEditorInstance(object);
        return object;
      }
    };
  }
  if (window.Lyte) {
    _led.constructExposableObject();
  }
  for (let key in exposableApi) {
    _led.addApi(key, exposableApi[key]);
  }
  window.lyteEditor = _led

  return _led;
}());
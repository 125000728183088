_led.addFeature("directory", function () {
  //No I18n
  const openedFiles = [];
  const dirtyFiles = {};
  const categoryModelUriMapping = {};
  let currentlyActiveFile = null;
  let editorInstance;
  let editorType;
  const type = _led.checkType;

  const createFile = function (underFolder, fileName) {
    const folder = editorInstance.folder.getEntry(underFolder);
    if (folder) {
      const newEntityStructure = folder.newEntityStructure;
      let createdFile = null;
      for (let category in newEntityStructure) {
        const ext = _led.Utils.langToExt(newEntityStructure[category]);
        const fileNameWithExt = fileName + "." + ext;
        createdFile = folder.create.file(fileNameWithExt, category);
      }
      return createdFile;
    } else {
      _led.throw.error("Folder named " + underFolder + " is not present"); //No I18n
    }
  };
  const createComponentFiles = function (componentName) {
    const underFolder = _led.LYTE_FILE_TYPES.COMPONENT;
    const folder = editorInstance.folder.getEntry(underFolder);
    if (folder) {
      const componentFolder = folder.create.folder(componentName, {
        allowSubFolderCreation: false,
        folderNameValidation: function (compName) {
          return _led.Utils.compNameValidation(
            compName,
            function success() {
              return true;
            },
            function error() {
              return false;
            }
          );
        }
      });
      const newEntityStructure = folder.newEntityStructure;
      const createdFiles = [];
      for (let category in newEntityStructure) {
        const ext = _led.Utils.langToExt(newEntityStructure[category]);
        const fileName = componentName + "." + ext;
        createdFiles.push(componentFolder.create.file(fileName, category));
      }
      return {
        folder: componentFolder,
        filesInFolder: createdFiles
      };
    } else {
      _led.throw.error("Folder named " + underFolder + " is not present"); //No I18n
    }
  };
  const setRootId = function (id) {
    if (id) {
      const rootDiectory = editorInstance.folder.dirContent;
      for (let key in rootDiectory) {
        const value = rootDiectory[key];
        value.parentDir = id;
      }
    } else {
      console.error("Root Id setting error");
    }
  };
  const findFileFrom = function (fileId, callback) {
    const file = editorInstance.folder.getDirectoryData(fileId);
    if (file) {
      return callback(file);
    } else {
      _led.throw.error("File is not found in the path " + filePath); //No I18n
    }
  };
  const setDirtyStateOfFile = function (filePath, dirtyState) {
    const args = Array.from(arguments);
    findFileFrom(filePath, function (file) {
      file.setDirtyStateAs.apply(file, args.slice(1));
      if (dirtyState) {
        dirtyFiles[filePath] = file;
      } else {
        delete dirtyFiles[filePath];
      }
    });
  };
  const getSingleDirectory = function (folder) {
    const type = folder.type;
    if (type == "folder") {
      const parentDir = folder.listObjFactory();
      parentDir.children = folder.list();
      return parentDir;
    } else if (type == "file") {
      return folder.listObjFactory();
    }
  };
  const getParentDir = function (childId) {
    return findFileFrom(childId, function (childDir) {
      return findFileFrom(childDir.parentDir, function (parentFile) {
        return parentFile;
      });
    });
  };
  const getOrderIndex = function (childId) {
    const childName = editorInstance.folder.getDirectoryData(childId).name;
    const parentFile = getParentDir(childId);
    return parentFile.order.indexOf(childName);
  };
  const activateFile = function () {
    findFileFrom(filePath, function (file) {
      file.activate();
    });
  };
  const deactivateFile = function () {
    findFileFrom(filePath, function (file) {
      file.deactivate();
    });
  };
  const doesFileHasMonacoModel = function (file) {
    // TODO:This check has to be more specific to monaco model...
    // - For now, it is done like this
    return !!file.meta.model;
    //INFO{{varun}}: my logic na.
    // const monacoRef = editorInstance.getData("monaco");
    // if(monacoRef.editor.getModel(monacoRef.Uri.file(file.path))){
    // 	return true;
    // }
    // return false;
  };
  const addModelUriToCategory = function (category, uri) {
    const mappingsOfCategory = categoryModelUriMapping[category];
    if (mappingsOfCategory) {
      if (!mappingsOfCategory.includes(uri)) {
        mappingsOfCategory.push(uri);
      }
    }
  };
  const focusEditor = function (editor, isDiffType) {
    if (isDiffType) {
      const editorModels = editor.getModel();
      const modifiedEditorModel = editorModels.modified;
      // const uri = modifiedEditorModel.uri.toString();
      // addModelUriToCategory(category, uri); 
      if (modifiedEditorModel) {
        editor.focus();
        const lineNum = modifiedEditorModel.getLineCount();
        const contentColumn = modifiedEditorModel.getLineContent(lineNum).length + 1;
        editor.setPosition({
          column: contentColumn,
          lineNumber: lineNum
        });
      }
    } else {
      const editorModel = editor.getModel();
      // const uri = editorModel.uri.toString();
      // addModelUriToCategory(category, uri);
      if (editorModel) {
        editor.focus();
        const lineNum = editorModel.getLineCount();
        const contentColumn = editorModel.getLineContent(lineNum).length + 1;
        editor.setPosition({
          column: contentColumn,
          lineNumber: lineNum
        });
      }
    }
  }
  const isMediaFiles = function (file) {
    const mediaExt = ["jpg", "jpeg", "gif", "png", "bmp", "svg", "webp", "ico", "mp4", "mkv", "mov", "webm", "flac", "wav", "mp3", "ogg", "m4a", "m4r", "ogv"];
    return mediaExt.includes(file.ext);
  };
  const restrictBinaryFormat = function (file) {
    const mediaExt = ["woff2", "eot", "ttf"];
    return mediaExt.includes(file.ext);
  };
  const setMediaQuery = function (file) {
    const mediaType = {
      image: ["jpg", "jpeg", "gif", "png", "bmp", "svg", "webp", "ico"],
      video: ["mp4", "ogv", "mov", "webm"],
      audio: ["flac", "wav", "mp3", "oga", "m4a"]
    };
    for (const key in mediaType) {
      const value = mediaType[key];
      if (value.includes(file.ext)) {
        editorInstance.setData('media', { type: key, src: file.value });
        break;
      }
    }
  };
  const loadEditor = function (file, alreadyLoaded) {
    const category = file.category;
    const editorNode = editorInstance.$node;
    const restrictedEditor = editorNode.restrictedEditor;
    let isDiffType = file.hasOwnProperty('originalValue');
    const diffEditor = Lyte.Editor.EDITOR_TYPES.DIFF_EDITOR;
    const basicEditor = Lyte.Editor.EDITOR_TYPES.BASIC_EDITOR;
    const currEditorType = editorInstance.data.ltPropEditorType;
    let model;
    const languageMap = {
      js: "javascript",
      html: "html",
      css: "css",
      txt: "plainText"
    }

    //base check
    if (isDiffType != (currEditorType == diffEditor)) {
      if (isDiffType || editorType == diffEditor) {
        isDiffType = true;
        editorInstance.setData('ltPropEditorType', diffEditor);
        editorInstance.initializePlaceHolder();
      }
      else {
        editorInstance.setData('ltPropEditorType', basicEditor);
        editorInstance.initializePlaceHolder();
      }

    }
    //set placeHolder falsy
    if (currentlyActiveFile && currentlyActiveFile != file) {
      // console.log('set switch ');
      editorInstance.setData('restrictPlaceHolder', true);
    }
    if (editorInstance.getData('switchFileTimeout')) {
      clearTimeout(editorInstance.getData('switchFileTimeout'));
    }
    if (isMediaFiles(file)) { //other media files
      return setMediaQuery(file)
    }
    else if (restrictBinaryFormat(file)) {
      return editorInstance.setData('media', { type: 'restricted' });
    }
    else if (editorInstance.getData('media')) {
      editorInstance.setData('media', void 0);
    }
    //loading part
    if (!alreadyLoaded) {

      //Handle not loaded model
      if (file.noBlueprints || !file.internal) {
        if (isDiffType) {
          //create two models
          model = editorNode.createModel(file.value, {

            language: languageMap[file.ext] || file.ext
          });
          const originalModel = editorNode.createModel(file.originalValue, {

            language: languageMap[file.ext] || file.ext
          })
          //setting model to diff editor
          editorInstance.setData('originalDiffModel', originalModel);
          editorInstance.setData('modifiedDiffModel', model);
          // file.meta.model = modifiedModel;
          file.meta.originalModel = originalModel;
          editorInstance.loadDiffModelToInstance();
          editorNode.setData("ltPropLanguage", _led.LANGS[file.ext]);
          file.meta.model = model;
        }
        else {
          model = editorNode.createModel(file.value, {
            language: languageMap[file.ext] || file.ext
          });
        }
      }
      else {
        const rangeConfig = file.rangeConfig;
        model = restrictedEditor.createModelWithRangeRestriction(
          file.value,
          {
            path: file.path
          },
          rangeConfig.editableRanges
        );
      }

      //handlel model after checking without bluprints and internal
      if (!isDiffType) {
        editorNode.setBasicEditorModel(model);
        editorNode.setData("ltPropLanguage", _led.LANGS[file.ext]);
        file.meta.model = model;
      }
      // - This line ensures the content of the editor and file are same
      // file.save(true); // ! This line has to be checked and removed if possible
    } else {
      if (isDiffType) {
        const originalModel = file.meta.originalModel;
        const diffModel = file.meta.model;
        editorInstance.setData('originalDiffModel', originalModel);
        editorInstance.setData('modifiedDiffModel', diffModel);
        editorInstance.loadDiffModelToInstance();
      }
      else {
        editorNode.setBasicEditorModel(file.meta.model);
      }
    }

    //focus monaco editor
    focusEditor(isDiffType ? editorNode.getData("diffEditorInstance") : editorNode.getData("editorInstance"), isDiffType);

    const timeout = setTimeout(function () {

      editorInstance.hidePlaceholder();
      editorInstance.setData('restrictPlaceHolder', false);

    }, 500);
    editorInstance.setData('switchFileTimeout', timeout)
  };
  const openFile = function (fileId) {
    if (currentlyActiveFile) {
      currentlyActiveFile.deactivate();
    }
    return findFileFrom(fileId, function (file) {

      if (openedFiles.includes(file)) {
        loadEditor(file, true);
      } else {
        openedFiles.push(file);
        loadEditor(file, doesFileHasMonacoModel(file));
      }
      currentlyActiveFile = file.activate();
      // First File is opened
      if (openedFiles.length === 1) {
        editorInstance.pubsub.fire("TABBAR_NOT_EMPTY"); //No I18n
      }
      editorInstance.pubsub.fire("ADD_TO_TAB_BAR", file.uniqueId, file); //No I18n
      return file;
    });
  };
  const closeFile = function (fileId) {
    return findFileFrom(fileId, function (file) {
      const indexOfFile = openedFiles.indexOf(file);
      if (indexOfFile > -1) {
        openedFiles.splice(indexOfFile, 1);
      }
      if (currentlyActiveFile === file) {
        currentlyActiveFile = null;
      } else {
        file.deactivate();
      }
      // Last File is closed
      if (openedFiles.length === 0) {
        editorInstance.pubsub.fire("TABBAR_EMPTY"); //No I18n
      }
      delete dirtyFiles[fileId];
      return file;
    });
  };
  const isFileHavingSyntaxError = function () {
    const errorMarkings = editorInstance.$node.getMarkingsInModel("Error"); //No I18n
    return errorMarkings.length > 0;
  };
  const canCloseFile = function (filePath) {
    return findFileFrom(filePath, function (file) {
      // Conditions to pass in order for a file to close properly
      const conditionsForFailure = [
        { condition: file.isDirty, WARNING: "IS_DIRTY" } //No I18N
      ];
      // { condition: isFileHavingSyntaxError, WARNING: "SYNTAX_ERROR" }//No I18N
      for (let i = 0, len = conditionsForFailure.length; i < len; i++) {
        const condition = conditionsForFailure[i].condition;
        if (type.boolean(condition)) {
          if (condition) {
            return { value: false, warning: conditionsForFailure[i].WARNING };
          }
        } else if (type.function(condition)) {
          if (condition()) {
            return { value: false, warning: conditionsForFailure[i].WARNING };
          }
        }
      }
      return { value: true };
    });
  };
  const renameFile = function (filePath, newName) {
    return findFileFrom(filePath, function (file) {
      return file.rename(newName);
    });
  };
  const deleteFile = function (filePath) {
    return findFileFrom(filePath, function (file) {
      return file.delete();
    });
  };
  const getDirectoryList = function () {
    const rootFolder = editorInstance.folder.getRootDirectory();
    const directoryChild = rootFolder.list();
    const directory = rootFolder.listObjFactory();
    directory.children = directoryChild;
    return directory;
  };
  const listOfOpenedFiles = function () {
    return openedFiles.slice();
  };
  const listOfDirtyFiles = function () {
    return dirtyFiles;
  };
  const getCurrentlyActiveFile = function () {
    return currentlyActiveFile;
  };
  const saveCurrentFile = function (file) {
    file = file || currentlyActiveFile;
    return file
      .save()
      .then(function () { })
      .catch(function (err) {
        console.log(err);
      });
  };
  const saveAllDirtyFiles = function (success, failure) {
    const promises = [];
    openedFiles.forEach(function (file) {
      if (file.isDirty) {
        promises.push(
          file.save().then(function () {
            if (success) {
              success();
            }
          }).catch(function (err) {
            if (failure) {
              failure();
            }
            console.log(err);
          })
        )
      }
    });
    Promise.all(promises).then(function () {
      if (success) {
        success();
      }
    })
  };
  const getStateOfFiles = function () {
    const files = openedFiles.map(function (value) {
      return value.id;
    });
    return {
      openedFiles: files,
      currentFile: currentlyActiveFile ? currentlyActiveFile.id : ""
    };
  };
  const manipulators = {
    setEditorInstance: function (editor) {
      editorInstance = editor;
      editorType = editor.data.ltPropEditorType || Lyte.Editor.EDITOR_TYPES.BASIC_EDITOR;
    },
    setEditorType: function (type) {
      editorType = type;
    },
    getEditorInstance: function () {
      return editorInstance;
    },
    addEditorActions: function () {
      editorInstance.$node.editorActions.add(function (args) {
        const KeyCode = args.KeyCode;
        const KeyMod = args.KeyMod;
        //ignorei18n_start
        return [
          {
            id: "save-current-file",
            label: "Save Current File",
            keybindings: [KeyMod.CtrlCmd | KeyCode.KeyS],
            run: function (editor) {
              editorInstance.directory.saveCurrentFile();
            }
          }
        ];
      });
    }
  };
  const dirObjFactory = function (keys, inputFileObj) {

    return keys.reduce(function (acc, eachKey) {
      const value = inputFileObj[eachKey];
      if (!(value == undefined)) {
        acc[eachKey] = value;
      }
      return acc;
    }, {})
  }
  const fileObjFactory = function (inputFileObj) {
    const keys = ["name", "value", "lastSavedValue", "isDirty"];
    return dirObjFactory(keys, inputFileObj);
  }
  const folderObjFactory = function (inputFolderObj) {
    const keys = ["name"];
    return dirObjFactory(keys, inputFolderObj);
  }
  const generateFileExplorerData = function (fileData) {
    const dirChildren = fileData.order;
    if (dirChildren) {
      const dirContent = fileData.dirContent;
      const folderObj = folderObjFactory(fileData);
      folderObj.children = dirChildren.reduce(function (acc, eachDir) {
        eachDir = dirContent[eachDir];
        acc.push(generateFileExplorerData(eachDir));
        return acc;
      }, [])
      return folderObj;
    }
    else {
      return fileObjFactory(fileData);
    }
  };
  const getFileModelObj = function (fileId, keys) {
    const file = editorInstance.folder.getDirectoryData(fileId);
    const reqKeys = keys || ["name", "value", "ext", "isDirty", "uniqueId"];
    const dirObj = dirObjFactory(reqKeys, file);
    const isDiffEditorType = file.meta.originalModel;

    function changeModelValue(givenValue) {
      if (typeof givenValue == 'object') {
        const original = givenValue.original;
        const modified = givenValue.modified;
        if ((original || original == '') && (modified || modified == '')) {
          originalModelChange(original);
          modifiedModelChange(modified);
        }
      }
      else if (typeof givenValue == 'string') {
        modifiedModelChange(givenValue);
      }
      if (editorInstance && editorInstance.data) {
        const editor = editorInstance.data[isDiffEditorType ? 'diffEditorInstance' : 'editorInstance'];
        editor && focusEditor(editor, isDiffEditorType);
      }

    }
    function getModelValue() {
      if (file.hasOwnProperty('originalValue')) {
        return {
          original: file.originalValue,
          modified: file.value
        }
      }
      else {
        return file.value;
      }
    }
    function originalModelChange(value) {
      file.setValue(value, true);
      file.meta.originalModel.setValue(value);
      saveCurrentFile();
    }
    function modifiedModelChange(value) {
      file.setValue(value);
      if (file.meta.model) {
        file.meta.model.setValue(value);
        saveCurrentFile();
      }
    }
    if (isDiffEditorType) {
      dirObj.model = {
        original: file.meta.originalModel,
        modified: file.meta.model
      }


    }
    else if (file.meta.model) {
      dirObj.model = file.meta.model;
    }
    dirObj.modelActions = {
      setValue: changeModelValue,
      getValue: getModelValue
    }
    dirObj.path = editorInstance.folder.generatePathUrl(fileId);
    return dirObj;
  }
  const getFolderModelObj = function (fileId, keys) {
    const file = editorInstance.folder.getDirectoryData(fileId);
    const reqKeys = keys || ["name", "uniqueId", "path"];
    const dirObj = dirObjFactory(reqKeys, file);
    return dirObj;
  }
  const API = Object.create(manipulators);
  const methods = {
    createFile: createFile,
    setRootId,
    createComponentFiles: createComponentFiles,
    setDirtyStateOfFile: setDirtyStateOfFile,
    activateFile: activateFile,
    deactivateFile: deactivateFile,
    openFile: openFile,
    getOrderIndex,
    closeFile: closeFile,
    canCloseFile: canCloseFile,
    getFolderModelObj,
    renameFile: renameFile,
    deleteFile: deleteFile,
    getDirectoryList: getDirectoryList,
    getParentDir: getParentDir,
    getSingleDirectory: getSingleDirectory,
    listOfOpenedFiles: listOfOpenedFiles,
    listOfDirtyFiles: listOfDirtyFiles,
    getCurrentlyActiveFile: getCurrentlyActiveFile,
    saveCurrentFile: saveCurrentFile,
    saveAllDirtyFiles: saveAllDirtyFiles,
    loadEditor: loadEditor,
    getFileModelObj,
    generateFileExplorerData: generateFileExplorerData,
    getStateOfFiles: getStateOfFiles,
    isMediaFiles
  };
  return _led.defineProp.call(API, _led.DESCRIPTOR_CODES[6], methods);
});
